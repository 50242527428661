import { TableData, Icons, Labels } from "@/Mixins/PageMixinData.js";
import { TableTop, TableActions } from "@/Mixins/PageMixinComponent.js";
import {
  handlerCopyToClipboard,
  handlerOpenNewTab,
} from "@/helpers/handleLinks.js";

export const PageMixin = {
  data: () => ({
    loading: false,
    loadingForm: false,
    search: "",
    selected: null,
    itemId: "_id",
    itemName: "",
    itemDisabled: "eliminado",
    dialog: false,
    filterMenu: false,
    filters: {},
    table: TableData,
    labels: Labels,
    icons: Icons,
    refs: {
      modal: "formDialog",
      confirm: "confirm",
    },
  }),
  components: {
    // Modal para confirmar una accion
    ConfirmModal: () => import("@/components/Modal/ConfirmModal.vue"),
    TableTop,
    TableActions,
  },
  created() {
    this.setPage();
    this.setup();
  },
  computed: {
    items: () => [],

    bind() {
      return {
        search: {
          class: "mt-0 align-self-end",
          [this.pagination ? "append-outer-icon" : "append-icon"]: this
            .pagination
            ? this.icons.searchClick
            : this.icons.search,
          label: this.labels.search,
          "single-line": true,
          "hide-details": true,
          clearable: true,
          dense: true,
        },
        divider: {
          vertical: true,
          class: "mx-3",
          inset: true,
        },
        refresh: {
          color: "primary",
          outlined: true,
          small: true,
          fab: true,
          class: "mr-3",
          loading: this.loading,
        },
        new: {
          color: "primary",
        },
        table: {
          loading: this.loading,
          "footer-props": this.table.footerProps,
          "loading-text": this.table.loadingText,
          "no-data-text": this.table.emptyText,
          class: "elevation-1",
          headers: this.headers,
          items: this.items,
        },
        tableTopContainer: {
          class: ["d-flex", "pa-3"],
        },
        form: {
          ref: this.refs.modal,
          "form-data": this.selected,
          loading: this.loadingForm,
          id: this.itemId,
        },
        activate: (item) => {
          return {
            "input-value": !item.eliminado,
            hint: item.eliminado ? "Inactivo" : "Activo",
            "true-value": true,
            "false-value": false,
            dense: true,
            inset: true,
            color: "primary",
            "persistent-hint": true,
            loading: item.loading,
          };
        },
        actions: {
          "x-small": true,
          fab: true,
          text: true,
          color: "primary",
        },
      };
    },
    bindChain() {
      return {
        tableTop: {
          containerProps: this.bind.tableTopContainer,
          searchValue: this.search,
          searchProps: this.bind.search,
          dividerProps: this.bind.divider,
          refreshProps: this.bind.refresh,
          refreshIcon: this.icons.refresh,
          newProps: this.bind.new,
          newLabel: this.labels.new,
        },
        tableActions: {
          actionsProps: this.bind.actions,
          editIcon: this.icons.edit,
          deleteIcon: this.icons.delete,
        },
      };
    },

    on() {
      return {
        search: {
          "click:append": this.setup,
          "click:clear": this.setup,
        },
        refresh: {
          click: this.setup,
        },
        new: {
          click: this.openForm,
        },
        table: {
          edit: this.openForm,
          delete: this.deleteItem,
        },
        form: {
          close: this.closeForm,
          submit: this.saveItem,
        },
        activate: (item) => {
          return {
            change: ($event) => this.activateItem($event, item),
          };
        },
        edit: (item) => {
          return {
            click: () => this.selectItem(item),
          };
        },
        delete: (item) => {
          return {
            click: () => this.deleteItem(item),
          };
        },
      };
    },

    onChain() {
      return {
        tableTop: {
          search: (value) => (this.search = value),
          new: this.on.new.click,
          refresh: this.on.refresh.click,
        },
        tableActions: (item) => {
          return {
            edit: this.on.edit(item).click,
            delete: this.on.delete(item).click,
          };
        },
      };
    },

    confirm() {
      return this.$refs[this.refs.confirm]?.show;
    },
  },
  methods: {
    // Funcion base para cargar data del componente
    setup: () => null,
    // Funcion para recuperar data base de la pagina
    setPage() {
      const pageNumber = this.$route.meta.page ?? this.page ?? "01";
      this.$store.commit("setPage", { page: pageNumber });
    },
    // Funcion para mostrar mensajes
    snackbar(text = "", color = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: text,
        top: true,
        right: true,
        color: color,
      });
    },
    // Funcion base para abrir el formulario del componente
    openForm(item = null) {
      if (item && !(item instanceof PointerEvent)) {
        this.selected = item;
      }
      this.dialog = true;
    },
    // Funcion base para cerrar el formulario del componente
    closeForm() {
      this.dialog = false;
      this.selected = null;
      this.$refs[this.refs.modal]?.reset();
    },
    selectItem(item = null) {
      this.selected = item;
      this.dialog = true;
    },
    // Funcion para cerrar y restaurar el formulario en caso de que este en el mismo componente y no en uno externo.
    resetForm: () => null,
    // Funcion para guardar la data del formulario en el componente
    saveItem(data) {
      this.loadingForm = true;
      let method = this.saveMethod();
      const formData = { ...data };
      if (this.selected) {
        method = this.updateMethod();
        formData.id = this.selected[this.itemId];
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((error) => {
          this.snackbar(this.handlerGetError(error));
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    saveMethod: () => null,
    updateMethod: () => null,
    // Funcion para eliminar un elemento de la tabla en el componente
    deleteItem(
      item,
      title = "Eliminar elemento",
      description = "Esta a punto de eliminar un elemento del listado ¿Desea proceder?"
    ) {
      const method = this.deleteMethod();
      this.confirm({
        title,
        description,
      }).then((answer) => {
        if (answer) {
          item.loading = true;
          method(item[this.itemId])
            .then((response) => {
              this.snackbar(response.info, "success");
              this.setup();
            })
            .catch((error) => {
              this.snackbar(this.handlerGetError(error));
            })
            .finally(() => {
              item.loading = false;
            });
        }
      });
    },
    deleteMethod: () => null,
    // Funcion para activar/desactivar un elemento de la tabla en el componente
    activateItem(item) {
      item.loading = true;
      const method = this.activateMethod();
      method({
        id: item[this.itemId],
        value: item[this.itemDisabled],
      })
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((error) => {
          this.snackbar(this.handlerGetError(error));
        })
        .finally(() => {
          item.loading = false;
        });
    },
    activateMethod: () => null,

    // Funcion para copiar o abrir enlaces
    handlerLink(link, action = "open") {
      if (action === "copy") {
        handlerCopyToClipboard(link);
      } else {
        handlerOpenNewTab(link);
      }
    },
    // Funcion para obtener el nombre del elemento seleccionado
    handlerItemName(item = null) {
      return item?.[this.itemName];
    },
    handlerGetError(error) {
      return (
        error.result?.data?.lista_mensaje?.[0]?.mensaje ||
        error.result?.message ||
        error.info
      );
    },
  },
};

export default PageMixin;
