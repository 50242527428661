// Importa Vuetify
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

// Crea una instancia de Vuetify
const vuetify = new Vuetify();

function getVuetifyColorHex(name) {
  // Accede a los colores del tema
  const themeColors = vuetify.framework.theme.themes.light;
  // Verifica si el nombre de color existe
  if (name in themeColors) {
    // Accede al color en formato hexadecimal
    const hexColor =
      typeof themeColors[name] === "string"
        ? themeColors[name]
        : themeColors[name].base;
    return hexColor;
  } else {
    // Devuelve un valor predeterminado o maneja el error según sea necesario
    return "#000000"; // Por ejemplo, negro
  }
}

export const darkorlightStyle = (color) => {
  if (color === "white") {
    return false;
  }
  if (typeof color === "string") {
    let c;
    const colorArray = color.split("#");
    if (colorArray[0] == "#") {
      c = colorArray[1];
    } else {
      c = getVuetifyColorHex(color)?.substring(1);
    }
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    let darkColor = false;
    if (luma < 128) {
      darkColor = true;
    }
    return darkColor;
  } else {
    return false;
  }
};

export const getFileExtension = (fileName = "") => {
  //Recibe el nombre del archivo y obtiene la extension.
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const toBase64 = function (file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        const fileData = reader.result.toString().split(",");
        let myFile = fileData[1];
        resolve(myFile);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    } catch (e) {
      reject(false);
    }
  });
};

export const setCodeFormat = function (textValue, upperCase = true) {
  let newtextValue = (
    typeof textValue === "string" ? textValue : textValue.toString()
  ).replace(/ /g, "_");
  if (upperCase) {
    newtextValue = newtextValue.toUpperCase();
  } else {
    newtextValue = newtextValue.toLowerCase();
  }
  return newtextValue;
};

export default {
  darkorlightStyle,
  toBase64,
  getFileExtension,
  setCodeFormat,
};
