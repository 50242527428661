import { Icons } from "./PageMixinData.js";
import { setCodeFormat } from "@/store/resources/tools.js";
import { FormModal } from "@/Mixins/PageMixinComponent.js";

export default {
  data: () => ({
    defaultTitle: "",
    labels: {
      submit: "Guardar",
    },
    width: 500,
    icons: Icons,
    form: undefined,
    formRef: "form",
    localUpdate: false,
    loadingForm: false,
  }),
  components: {
    FormModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "_id",
    },
  },
  watch: {
    value: function (newVal) {
      if (newVal) {
        this.setup();
        if (this.formData) {
          this.setter();
        }
      }
    },
  },
  computed: {
    formComponent() {
      return this.$refs[this.formRef];
    },
    bind() {
      return {
        title: this.title || this.defaultTitle || "Form",
        dialog: {
          "max-width": this.width,
          persistent: this.localUpdate ? this.loadingForm : this.loading,
          value: this.value,
          scrollable: true,
        },
        close: {
          color: "primary",
          text: true,
          fab: true,
          "x-small": true,
          disabled: this.localUpdate ? this.loadingForm : this.loading,
        },
        submit: {
          text: true,
          color: "primary",
          loading: this.localUpdate ? this.loadingForm : this.loading,
        },
      };
    },
    bindChain() {
      return {
        form: {
          dialogProps: this.bind.dialog,
          title: this.bind.title,
          closeProps: this.bind.close,
          closeIcon: this.icons.close,
          submitProps: this.bind.submit,
          submitLabel: this.labels.submit,
        },
      };
    },
    on() {
      return {
        dialog: {
          "click:outside": this.close,
        },
        close: {
          click: this.close,
        },
        submit: {
          click: this.submit,
        },
      };
    },
    onChain() {
      return {
        form: {
          close: this.on.close.click,
          submit: this.on.submit.click,
        },
      };
    },
  },
  methods: {
    setup: () => null,
    submit() {
      if (this.formComponent) {
        if (this.formComponent.validate()) {
          this.send();
        }
      } else {
        this.snackbar("Ha ocurrido un error al procesar el formulario.");
      }
    },
    send() {
      this.$emit("submit", this.form);
    },
    close() {
      this.$emit("close");
    },
    setter() {
      for (const i in this.formData) {
        if (i in this.form) {
          this.form[i] = this.formData[i];
        }
      }
    },
    reset() {
      for (const i in this.form) {
        if (Array.isArray(this.form?.[i])) {
          this.form[i] = [];
        } else {
          switch (typeof this.form?.[i]) {
            case "string":
              this.form[i] = "";
              break;
            case "number":
              this.form[i] = 0;
              break;
            case "boolean":
              this.form[i] = false;
              break;
            default:
              this.form[i] = null;
              break;
          }
        }
      }
      this.formComponent?.resetValidation();
    },
    // Funcion para mostrar mensajes
    snackbar(text = "", color = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: text,
        top: true,
        right: true,
        color: color,
      });
    },
    // Funcion para settear el campo codigo de la mayoria de mantenedores.
    codeFormat: (e, up = true) => setCodeFormat(e, up),
  },
};
