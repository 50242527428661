export const TableData = {
  footerProps: {
    "items-per-page-text": "mostrar:",
    "items-per-page-options": [5, 10, 25, 50],
  },
  loadingText: "Cargando... espere un momento.",
  emptyText: "No se han encontrado registros.",
};

export const Icons = {
  search: "mdi-magnify",
  searchClick: "mdi-magnify-expand",
  refresh: "mdi-refresh",
  edit: "mdi-pencil-outline",
  delete: "mdi-delete-outline",
  filter: "mdi-filter",
  close: "mdi-close",
  detail: "mdi-eye-outline",
  copy: "mdi-clipboard-text-outline",
  open: "mdi-open-in-new",
  activate: "mdi-checkbox-marked-circle",
  info: "mdi-information",
};

export const Labels = {
  search: "Buscar...",
  new: "Nuevo",
  delete: "Eliminar",
  edit: "Modificar",
  activate: "Activar",
  deactivate: "Desactivar",
  enabled: "Activo",
  disabled: "Inactivo",
  openLink: "Abrir enlace",
  copyLink: "Copiar enlace",
};

export default {
  TableData,
  Icons,
  Labels,
};
