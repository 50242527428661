import store from "@/store";

export const handlerCopyToClipboard = async function (link = "") {
  const message = {
    active: true,
    right: true,
    top: true,
    color: "primary",
  };
  try {
    await navigator.clipboard.writeText(link).then(() => {
      message.text = "El enlace se ha copiado al portapapeles!";
      store.commit("setSnackbar", message);
    });
  } catch {
    message.color = "error";
    message.text = "Error al intentar copiar el link en el portapapeles.";
    store.commit("setSnackbar", message);
  }
};

export const handlerOpenNewTab = function (link = "") {
  const message = {
    active: true,
    right: true,
    top: true,
    color: "primary",
  };
  try {
    message.text = "Abriendo enlace...";
    message.timeout = 2000;
    store.commit("setSnackbar", message);
    setTimeout(() => {
      window.open(link, "_blank");
    }, 1000);
  } catch {
    message.color = "error";
    message.text = "Ha ocurrido un error al intentar abrir el enlace.";
    store.commit("setSnackbar", message);
  }
};

export default {
  handlerCopyToClipboard,
  handlerOpenNewTab,
};
