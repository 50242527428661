<template>
  <v-container>
    <v-card>
      <v-data-table v-bind="bind.table" :search="search">
        <template #top>
          <div class="d-flex align-center px-3 py-2">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon></v-btn
            >
            <v-btn v-bind="bind.new" v-on="on.new">
              {{ labels.new }}
            </v-btn>
          </div>
        </template>

        <!-- COPIAR ENLACE BOTON -->
        <template #item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                v-on="on"
                v-bind="{ ...attrs, ...bind.actions }"
                @click="handlerLink(item.url, 'copy')"
              >
                <v-icon>{{ icons.copy }}</v-icon>
              </v-btn>
            </template>
            <span>{{ labels.copyLink }}</span>
          </v-tooltip>

          <!-- ABRIR ENLACE BOTON -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                v-on="on"
                v-bind="{ ...attrs, ...bind.actions }"
                @click="handlerLink(item.url)"
              >
                <v-icon>{{ icons.open }}</v-icon>
              </v-btn>
            </template>
            <span>{{ labels.openLink }}</span>
          </v-tooltip>

          <!-- EDITAR ITEM BOTON -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="{ ...attrs, ...bind.actions }"
                @click="selectItem(item)"
              >
                <v-icon>{{ icons.edit }}</v-icon>
              </v-btn>
            </template>
            <span>{{ labels.edit }}</span>
          </v-tooltip>

          <!-- ELIMINAR BOTON -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="error"
                v-on="on"
                v-bind="{ ...attrs, ...bind.actions }"
                @click="deleteItem(item)"
              >
                <v-icon>{{ icons.delete }}</v-icon>
              </v-btn>
            </template>
            <span>{{ labels.delete }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import Modal from "@/components/Modal/ModalIntegrator/Modal-Servicio";
import { mapActions } from "vuex";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "ServicesIntegratorPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Empresa", value: "empresa.nombre", align: "left" },
      { text: "Servicio", value: "nombre", align: "left" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    servicios: [],
  }),

  created() {
    this.setup();
  },

  computed: {
    items() {
      return this.servicios;
    },
  },

  methods: {
    ...mapActions("Integrator", [
      "getServices",
      "CrearServicio",
      "UpdateServicio",
      "deleteServicio",
    ]),

    async setup() {
      this.loading = true;
      await this.getServices()
        .then((resp) => {
          this.servicios = resp.servicios;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem(data) {
      this.loadingForm = true;
      let METHOD = this.CrearServicio;
      let formData = {};
      if (this.selected) {
        METHOD = this.UpdateServicio;
        formData.id = this.selected.id;
        formData.data = { ...data };
      } else {
        formData = { ...data };
      }
      METHOD(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((response) => {
          this.snackbar(response.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      item.loading = true;
      this.$refs[this.refs.confirm]
        .show({
          title: "Eliminar servicio",
          description:
            "¿Seguro que quiere eliminar definitivamente este servicio?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteServicio(item.id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((error) => {
                this.snackbar(error.info);
              })
              .finally(() => {
                item.loading = false;
              });
          }
        });
    },
  },
};
</script>
