<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row>
            <!-- CAMPO EMPRESA -->
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="form.id_empresa"
                :items="empresas"
                item-text="empresa_descripcion"
                item-value="id"
                label="Empresa"
                :loading="loadingEmpresas"
                :rules="[(v) => !!v || 'Debe seleccionar una empresa.']"
              ></v-autocomplete>
            </v-col>

            <!-- CAMPO TIPO -->
            <v-col cols="12" md="6">
              <v-select
                v-model="form.tipo"
                :items="tipos"
                label="Tipo"
                :rules="[(v) => !!v || 'Debe seleccionar un tipo.']"
              ></v-select>
            </v-col>

            <!-- CAMPO NOMBRE -->
            <v-col cols="12">
              <v-text-field
                v-model="form.nombre"
                name="nombre"
                label="Nombre"
                placeholder="Nuevo nombre de servicio"
                :rules="[(v) => !!v || 'El campo nombre es requerido.']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO URL -->
            <v-col cols="12">
              <v-text-field
                v-model="form.url"
                name="url"
                label="Url"
                placeholder="Ingrese la url..."
                :rules="[(v) => !!v || 'El campo url es requerido.']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO TEMPLATE -->
            <v-col cols="12">
              <v-textarea
                v-model="form.template"
                name="template"
                label="Template"
                placeholder="Nuevo template"
                :rules="[
                  (v) => !!v || 'El campo template no puede quedar vacío.',
                ]"
              >
              </v-textarea>
            </v-col>

            <!-- CAMPO TEMPLATE SALIDA -->
            <v-col cols="12">
              <v-textarea
                v-model="form.template_salida"
                name="templateSalida"
                label="Template de Salida"
                placeholder="Ingrese el template de salida..."
                :rules="[
                  (v) =>
                    !!v || 'El campo template de salida no puede quedar vacío.',
                ]"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "ModalFormularioServicios",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de Servicios",
      form: {
        nombre: "",
        template: "",
        template_salida: "",
        url: "",
        tipo: "json",
        id_empresa: undefined,
      },
      loadingEmpresas: false,
      empresas: [],
      tipos: [
        { text: "JSON", value: "json" },
        { text: "XML", value: "xml" },
      ],
    };
  },
  methods: {
    ...mapActions("Integrator", ["getCompanies"]),

    setup() {
      this.getEmpresa();
    },

    async getEmpresa() {
      this.loadingEmpresas = true;
      await this.getCompanies()
        .then((resp) => {
          this.empresas = resp;
        })
        .finally(() => {
          this.loadingEmpresas = false;
        });
    },

    setter() {
      this.form.nombre = this.formData.nombre;
      this.form.template = this.formData.template;
      this.form.template_salida = this.formData.template_salida;
      this.form.url = this.formData.url;
      this.form.tipo = this.formData.tipo;
      if ("empresa" in this.formData) {
        this.form.id_empresa = this.formData.empresa.id;
      } else {
        this.form.id_empresa = this.formData.id_empresa;
      }
    },
  },
};
</script>
