export const TableTop = () =>
  import("@/components/Tables/tableTopElements.vue");
export const TableActions = () =>
  import("@/components/Tables/tableActionElements.vue");
export const FormModal = () => import("@/components/Modal/formModal.vue");

export default {
  TableTop,
  TableActions,
  FormModal,
};
